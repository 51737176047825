

/* .overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
} */
.overlay{
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    overflow-y: hidden;    
    position: fixed;
    top:0;
    left:0;
    z-index: 10;
    touch-action: none;

}
.modalContainer{
    max-width: 1000px;
    width: 100%;
    position: fixed;
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    /* background: rgba(122, 122, 122, 0.2);
    color: white;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.1); */
    border-radius: 15px;
    /* background: rgb(41, 41, 41);
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2); */
    background: rgba(41, 41, 41, 0.6);
    border: 1px solid rgba(128, 127, 127, 0.459);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(4px);
    box-shadow: -3px 4px 8px 0 rgba(0, 0, 0, 0.4), -3px 4px 8px 0 rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.8);
    transition: 300ms;
    z-index: 1000;
}

.modalContent{
    text-align: center;
    width: 100%;
    margin: 30px;
}

.imageContainer{
    max-width: 50%;
    margin: 30px;
}

.modalLink{
    color: white;

}

.motion-div{
    top: 50%;
    left: 50%;
}

@media (max-width: 1028px) {

    .modalContainer{
        width: 40vh;
    }

    .imageContainer{
        display: none;
    }
}


@keyframes sketchIn {
	0% {
		stroke-dashoffset: 778;
	}
	100% {
		stroke-dashoffset: 0;
	}
}