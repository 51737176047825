
.App {
  width: 100%;
  text-align: center;
}

.home{
  width: 100%;
}

.home.modal-open{
  position: fixed;  
}

.gradient-text{
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
  background-color: black;
  mix-blend-mode: multiply;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 40px;
}

.gradient-text-2{
  position: relative;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: block;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
  background-color: black;
  mix-blend-mode: multiply;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.skills{
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 80px;
  margin: 0;
}


.nav {
 display: flex;
 justify-content: space-around;
 align-items: center;
 position: fixed;
 width: 100%;
 max-height: 6vh;
 background: rgba(122, 122, 122, 0.2);
 border-bottom: 1px solid rgba(255,255,255,0.1);
 color: white;
 -webkit-backdrop-filter: blur(15px);
 backdrop-filter: blur(10px);
 z-index: 10000;
 transition: 300ms;
 opacity: 0;
}

.nav.display{
  opacity: 1;
}

.nav.black{
  color: black;
  background: rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(128, 127, 127, 0.459);
  /* background-color: rgba(255, 255, 255, 0.5);   */
}


.nav-item{
  display: inline-block;
  margin: 20px;
  color: inherit;
  text-decoration: none;
  transition: 200ms;
}
.nav-item:hover{
  color: grey;
  transition: 200ms;
}

.mobile-header{
  display: none;
  margin: 0;
  padding: 0;
}



.first{
  height: 200vh;
  background:
        linear-gradient(217deg, rgba(25, 0, 255, 0.808), rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(127deg, rgba(174, 0, 255, 0.808), rgba(0, 60, 255, 0) 70.71%),
        linear-gradient(336deg, rgba(0, 190, 228, 0.8), rgba(255, 238, 0, 0.123) 70.71%);
	background-size: 200% 200%;
  width: 100%;
	animation: gradient 3s ease infinite;
}

.about-image{
  height: 60vh;
  margin-right: 90px;
  border-radius: 20px;
}



@keyframes gradient {
	0% {
		background-position: 25% 25%;
	}
	25% {
		background-position: 25% 75%;
	}
  50% {
		background-position: 75% 75%;
	}
  77% {
		background-position: 75% 25%;
	}
	100% {
		background-position: 25% 25%;
	}
}


.second{
    width: 100%;
    background-color: white;
    height: 115vh;
}

.third{
    background-color:black;
    height: 100vh;
}

.navbar{
  display: inline-block;
}

.scroll-element{
 
  display: block;
}

/* .scroll-container{
  padding-top: 10%;
} */


  ul.hero-list{
    list-style-type: none;
    margin:0;
    padding: 0;
  }

  .hero-viewport{
    position: relative;
    width: 100%;
    height: 130vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .viewport-div {
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-div {
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hero-div {
    position: relative;
    width: 100%;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .projects{
    position: -webkit-sticky;
    position: sticky;
    top: 25%;
    display: block;
    padding-bottom: 100vh;
    align-items: center;
    /* z-index: 10; */
  }

  .simple-card{
    display: inline-block;
    margin: 5%;
    align-items: center;

    /* z-index: 100; */
    width: 20vw;
  }

  .simple-title-card{
    display: inline-block;

    align-items: center;
    width: 20vw;
    height: 20vw;
    border-radius: 15px;
    /* background: rgb(41, 41, 41); */
    /* background: rgba(122, 122, 122, 0.2); */
    /* border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2); */
    background: rgba(41, 41, 41, 0.8);
    border: 1px solid rgba(128, 127, 127, 0.459);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(4px);
    box-shadow: -3px 4px 8px 0 rgba(0, 0, 0, 0.4), -3px 4px 8px 0 rgba(0, 0, 0, 0.4);
    transition: 300ms;
  }

  .simple-title-card:hover{
    transform: scale(1.01);
  }

  .simple-card-image{
    margin-top: 10%;
    width: 50%;
    background: white;
    border-radius: 15px;
  }

  .card{
    display: inline-block;
    margin: 5%;
    align-items: center;
    /* z-index: 100; */
    width: 20vw;
  }



  .title-card {
    display: inline-block;

    align-items: center;
    width: 20vw;
    height: 20vw;
    border-radius: 20px;
    background: rgb(41, 41, 41);
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    /* color: white;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(4px); */
    box-shadow: -3px 4px 8px 0 rgba(0, 0, 0, 0.4), -3px 4px 8px 0 rgba(0, 0, 0, 0.4);
    color: white;
    transition: 300ms;
  }

  .card-link{
    color: white;
    text-decoration: none;
    position:absolute; 
    width:100%;
    height:100%;
    top:0;
    left: 0;    
    /* z-index: 100; */
}

  .title-card:hover{
    transform: scale(1.01);
  }

  .card-image{
    margin-top: 10%;
    width: 50%;
    background: white;
    border-radius: 20px;
  }

  .card-title{
    font-weight: bold;
    padding-right: 10%;
    padding-left: 10%;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 40px;
    color: white;
  }

  .card-text{
    padding-right: 10%;
    padding-left: 10%;
    color: white;
    margin-top: 20px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 20px;
  }

  .white-title {
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 80px;
  }
  
  .black-title{
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 80px;
    margin:0;

  }

  .about-body{
    font-weight: bold;
    padding-right: 10%;
    padding-left: 10%;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 40px;
  }

  .element-container{
    margin:auto;

  }

  .contact{
    margin-top: 30%;
  }
  
  .fourth{
    background-color: black;
    height: 100vh;
    /* z-index: -1;
    position: relative; */
  }

  .blocker{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }


  .information{
    color: white;
    display: block;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    padding-top: 10px;
    position: relative;
    z-index: 5;
  }

 

/* 
#Video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
  }
  
  /* Add some content at the bottom of the video/page */
  .content {
    position: fixed;
    align-self: center;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;
  } 

  /* Form styling */

.form-container{
  margin: auto;
  width:500px;
  /* z-index: 10000; */
}

  .form-title{
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 80px;
    margin: 0;
  }

  html,body{
    scroll-behavior: smooth;
  }

  .form-submit{
    align-self: center;
    background-color: rgba(255, 255, 255, 0.615);
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 5px;
    width: 30%;
    color: white;
    margin-top: 1%;
  }

  .submit-div{
    text-align: center;
  }

  .form-group{
    margin: 0;
    padding:0;
  }

  .feedback-input {
    color:white;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight:500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background: rgb(41, 41, 41);
    border: 2px solid rgba(255, 255, 255, 0.2);
    transition: all 0.2s;
    padding: 13px;
    margin-bottom: 15px;
    width:100%;
    box-sizing: border-box;
    outline:0;
  }
  
  .feedback-input:focus { border:2px solid rgba(255, 255, 255, 0.774); }
  
  textarea {
    height: 150px;
    line-height: 150%;
    resize:vertical;
  }
  
  [type="submit"] {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 30%;
    background:rgba(0, 122, 255, 1);
    border-radius:3
    0px;
    border:0;
    cursor:pointer;
    color:white;
    font-size:24px;
    padding-top:10px;
    padding-bottom:10px;
    transition: all 0.2s;
    margin-top:-4px;
    font-weight:700;
  }
  [type="submit"]:hover { background:rgba(10, 132, 255, 1); }
  

    /* TABLET STYLING */
    @media(max-width: 1350px) and (min-width: 1028px){

      .card{
        display: block;
        margin: auto;
        width: 100%

      }

      .card-title{
        font-size: 30px;
      }

      .title-card{
        width: 30vw;
        height: 30vw;
      }
  
      .nav-item{
        
        margin:0;
        margin-left: 15px;
      }
  
      .mobile-header{
        display: inline-block;
      }
  
      .desktop-header{
        display: none;
      }
  
      .navbar{
        padding: 0;
        display: inline-block;
      }

      .form-container{
        width: 90%;
      }

      .form-title{
        font-size: 40px;
        /* margin-bottom: 10%; */
      }


      .black-title{
        font-size: 40px;
      }

      .about-body{
        font-size: 20px;
      }


      .about-div{
        margin: 0;
      }

      .projects{
        top: 10%;
      }

      .card-text{
        margin: 0;
      }

    }

    /* MOBILE STYLING */

    @media (max-width: 1028px) {

      .card{
        display: block;
        margin: auto;
        width: 100%

      }
      .simple-card{
        display: block;
        margin: auto;
        width: 100%
        }

      .card-title{
        font-size: 30px;
      }

      .card-text{
        display: none;
      }

      .title-card{
        width: 50vw;
        height: 50vw;
        
      }

      .simple-title-card{
        /* width: 80%; */
        height: 80%;
        
        width: 40vh;
        margin-bottom: 10%;
      }

      .rentsimple-icon{
        margin-top: 0;
      }

      .about-div {   
        flex-direction: column;
      }
  
      .nav-item{
        
        margin:0;
        margin-left: 15px;
      }
  
      .mobile-header{
        display: inline-block;
      }
  
      .desktop-header{
        display: none;
      }
  
      .about-image{
        margin: auto;
      }
  
      .navbar{
        padding: 0;
        display: inline-block;
      }
      /* html,body{
        overflow-x: hidden;
      }
   */
      .form-container{
        width: 90%;
      }

      .form-title{
        font-size: 40px;
        /* margin-bottom: 10%; */
      }


      .black-title{
        font-size: 40px;
      }

      .about-body{
        font-size: 20px;
      }

      .about-image{
        height: 40vh;
      }

      .about-div{
        margin: 0;
      }

      .projects{
        top: 10%;
      }

      .card-text{
        margin-top: 8px;
      }

      .skills{
        font-size: 40px;
        padding:5%;
      }

    }

  /* .form-input{
    text-align: left;
    display: inline-block;
    margin-top:auto;
    height: 4vh;
    background-color: rgba(255, 255, 255, 0.615);
    border: 1px solid rgba(255,255,255,0.1);
    width: 100%;
    border-radius: 5px;
    color: white;
    /* text-align: center; 
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 2%;
    ;
  }


  .message{
    /* display: block; 
    background-color: rgba(255, 255, 255, 0.615);
    border: 1px solid rgba(255,255,255,0.1);
    width: 100%;
    border-radius: 5px;
    height: 20vh;
    color: white;
    /* text-align: left; 
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 2%;
    vertical-align: text-top;
    
  }
  ::placeholder{
    color:rgba(255, 255, 255, 0.663);
    padding-left: 10px;
    
  } */
  